import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, vModelRadio as _vModelRadio, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"},
  "data-aos": "fade-left"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_5 = { class: "border border-primary bg-light rounded-2" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { class: "d-flex justify-content-end" }
const _hoisted_8 = {
  key: 0,
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#generate_link"
}
const _hoisted_9 = {
  key: 0,
  class: "d-flex ms-4 justify-content-en"
}
const _hoisted_10 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_3",
  ref: "closeHexModal"
}
const _hoisted_11 = { class: "modal-dialog" }
const _hoisted_12 = { class: "modal-content roundeda" }
const _hoisted_13 = { class: "modal-body p-15" }
const _hoisted_14 = { class: "row align-middle" }
const _hoisted_15 = {
  key: 0,
  class: "row my-3"
}
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 1,
  class: "row"
}
const _hoisted_18 = { class: "row align-middle" }
const _hoisted_19 = {
  key: 2,
  class: "row"
}
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "protocol_check my-3" }
const _hoisted_22 = { class: "protocol_check" }
const _hoisted_23 = { class: "col-6 mt-6" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "mt-3" }
const _hoisted_26 = { class: "tls_check my-3" }
const _hoisted_27 = { class: "tls_check" }
const _hoisted_28 = {
  key: 3,
  class: "row"
}
const _hoisted_29 = { class: "modal-footer" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 0,
  title: "Delivered",
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`),
  style: {"margin-left":"1.3rem"}
}
const _hoisted_32 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success ms-3`),
  title: "In Que"
}
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_GenerateDownlink = _resolveComponent("GenerateDownlink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode(" v-model=\"search\"\r\n              @input=\"searchItems()\" "),
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
          ]),
          _createElementVNode("span", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              type: "text",
              "data-kt-subscription-table-filter": "search",
              class: "form-control form-control-solid w-250px ps-5",
              placeholder: "Search Downlinks",
              style: {"padding-left":"2.5rem !important"}
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_6, [
          _createCommentVNode("begin::Toolbar"),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("select", {
              style: {"width":"150px"},
              class: "form-select border me-3 border-primary form-select-solid",
              "data-control": "select2",
              "data-dropdown-parent": "#kt_modal_1",
              "data-placeholder": "Select an option",
              "data-allow-clear": "true",
              onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onProtocolChange && _ctx.onProtocolChange(...args)))
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("option", {
                value: "udp",
                selected: ""
              }, "UDP", -1 /* HOISTED */),
              _createElementVNode("option", { value: "tcp" }, "TCP", -1 /* HOISTED */)
            ]), 32 /* NEED_HYDRATION */),
            _createElementVNode("select", {
              style: {"width":"150px"},
              class: "form-select border me-3 border-primary form-select-solid",
              "data-control": "select2",
              "data-dropdown-parent": "#kt_modal_1",
              "data-placeholder": "Select an option",
              "data-allow-clear": "true",
              onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onStatusChange && _ctx.onStatusChange(...args)))
            }, _cache[17] || (_cache[17] = [
              _createElementVNode("option", {
                value: "",
                selected: ""
              }, "All", -1 /* HOISTED */),
              _createElementVNode("option", { value: "0" }, "In Que", -1 /* HOISTED */),
              _createElementVNode("option", { value: "1" }, "Delivered", -1 /* HOISTED */)
            ]), 32 /* NEED_HYDRATION */),
            (_ctx.permissions.includes('compose-downlink'))
              ? (_openBlock(), _createElementBlock("button", _hoisted_8, " Compose Downlink "))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("Modal data "),
            _createCommentVNode("Modal data end  ")
          ]),
          (_ctx.permissions.includes('schedule-downlink'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "btn btn-success",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_3",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.edit = false))
                }, " Schedule Downlink ")
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header")
    ]),
    _createCommentVNode("Modal code send downlink start "),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "modal-header bg-primary roundeda" }, [
            _createElementVNode("span", { class: "modal-title text-white h5 text-uppercase ms-3" }, "Send DownLinks"),
            _createCommentVNode("begin::Close"),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "downlinkclosemodal",
              style: {"border-radius":"50px"}
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ]),
            _createCommentVNode("end::Close")
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Name:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.name) = $event)),
                class: "form-control my-3",
                placeholder: "Enter Name"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.payload.name]
              ])
            ]),
            (!_ctx.edit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold my-3" }, "Select Company:", -1 /* HOISTED */)),
                  _createElementVNode("select", {
                    class: "form-select form-select-solid border",
                    "data-control": "select2",
                    onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                    "data-dropdown-parent": "#kt_modal_1",
                    "data-placeholder": "Select an option",
                    "data-allow-clear": "true"
                  }, [
                    _cache[19] || (_cache[19] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: i,
                        value: customer.id
                      }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_16))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 32 /* NEED_HYDRATION */)
                ]))
              : _createCommentVNode("v-if", true),
            (!_ctx.edit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Device ID:", -1 /* HOISTED */)),
                  (_ctx.payload.se_account_id)
                    ? (_openBlock(), _createBlock(_component_Multiselect, {
                        key: 0,
                        options: _ctx.devices_options,
                        modelValue: _ctx.payload.devices,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.devices) = $event)),
                        mode: "tags",
                        searchable: "true"
                      }, null, 8 /* PROPS */, ["options", "modelValue"]))
                    : _createCommentVNode("v-if", true),
                  (!_ctx.payload.se_account_id)
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 1,
                        type: "text",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.devices) = $event)),
                        class: "form-control my-3"
                      }, null, 512 /* NEED_PATCH */)), [
                        [_vModelText, _ctx.payload.devices]
                      ])
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_18, [
              _cache[22] || (_cache[22] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Hex:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.downlink_hex) = $event)),
                class: "form-control my-3",
                placeholder: "enter HEX"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.payload.downlink_hex]
              ])
            ]),
            (!_ctx.edit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("fieldset", null, [
                      _cache[25] || (_cache[25] = _createElementVNode("h4", { class: "mt-3 text-primary" }, "Protocol", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_21, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.protocol) = $event)),
                          name: "protocol",
                          id: "tcp",
                          class: "fs-5",
                          value: "tcp"
                        }, null, 512 /* NEED_PATCH */), [
                          [_vModelRadio, _ctx.payload.protocol]
                        ]),
                        _cache[23] || (_cache[23] = _createElementVNode("label", {
                          for: "tcp",
                          class: "text-dark fs-5"
                        }, "TCP", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.protocol) = $event)),
                          name: "protocol",
                          id: "udp",
                          class: "fs-5",
                          value: "udp"
                        }, null, 512 /* NEED_PATCH */), [
                          [_vModelRadio, _ctx.payload.protocol]
                        ]),
                        _cache[24] || (_cache[24] = _createElementVNode("label", {
                          for: "udp",
                          class: "text-dark fs-5"
                        }, "UDP", -1 /* HOISTED */))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    (_ctx.payload.protocol == 'tcp')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("fieldset", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              _withDirectives(_createElementVNode("input", {
                                type: "radio",
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.tls) = $event)),
                                name: "tls",
                                id: "WithTLS",
                                class: "fs-5",
                                value: "1"
                              }, null, 512 /* NEED_PATCH */), [
                                [_vModelRadio, _ctx.payload.tls]
                              ]),
                              _cache[26] || (_cache[26] = _createElementVNode("label", {
                                for: "WithTLS",
                                class: "text-dark fs-5"
                              }, "With TLS", -1 /* HOISTED */))
                            ]),
                            _createElementVNode("div", _hoisted_27, [
                              _withDirectives(_createElementVNode("input", {
                                type: "radio",
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.tls) = $event)),
                                name: "tls",
                                id: "WithOutTLS",
                                class: "fs-5",
                                value: "0"
                              }, null, 512 /* NEED_PATCH */), [
                                [_vModelRadio, _ctx.payload.tls]
                              ]),
                              _cache[27] || (_cache[27] = _createElementVNode("label", {
                                for: "WithOutTLS",
                                class: "text-dark fs-5"
                              }, "WithOut TLS", -1 /* HOISTED */))
                            ])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.edit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _cache[29] || (_cache[29] = _createElementVNode("h4", { class: "text-primary my-3" }, "Update Status :", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-control border border-primary",
                    "data-control": "select2",
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.status) = $event)),
                    "data-dropdown-parent": "#kt_modal_1",
                    "data-placeholder": "Select an option",
                    "data-allow-clear": "true"
                  }, _cache[28] || (_cache[28] = [
                    _createElementVNode("option", { value: "1" }, "Delivered", -1 /* HOISTED */),
                    _createElementVNode("option", { value: "0" }, "In Que", -1 /* HOISTED */)
                  ]), 512 /* NEED_PATCH */), [
                    [_vModelSelect, _ctx.payload.status]
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_29, [
            _cache[30] || (_cache[30] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-light",
              "data-bs-dismiss": "modal"
            }, " Close ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.saveDownlink && _ctx.saveDownlink(...args))),
              class: "btn btn-primary"
            }, " Save changes ")
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("Modal code send downlink end "),
    _createCommentVNode("data table"),
    _createVNode(_component_KTDatatable, {
      style: {"margin-left":"30px !important"},
      "table-data": _ctx.data,
      total: _ctx.total,
      "table-header": _ctx.headerConfig,
      "enable-items-per-page-dropdown": true,
      loading: _ctx.loading,
      rowsPerPage: _ctx.pagination.page_size,
      onCurrentChange: _ctx.currentChange,
      onItemsPerPageChange: _ctx.itemsPerPageChange
    }, {
      "cell-name": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.name), 1 /* TEXT */)
      ]),
      "cell-device_id": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.device_id), 1 /* TEXT */)
      ]),
      "cell-customer_name": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.customer_name || "N/A"), 1 /* TEXT */)
      ]),
      "cell-downlink_hex": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.downlink_hex), 1 /* TEXT */)
      ]),
      "cell-send_ts": _withCtx(({ row: data }) => [
        (data.send_ts != 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.dateTime(data.send_ts)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      "cell-status": _withCtx(({ row: data }) => [
        (data.status == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[32] || (_cache[32] = [
              _createElementVNode("i", { class: "fas fa-check text-primary" }, null, -1 /* HOISTED */)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[33] || (_cache[33] = [
              _createElementVNode("i", { class: "bi bi-x-circle text-danger" }, null, -1 /* HOISTED */)
            ])))
      ]),
      "cell-action": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.action) + " ", 1 /* TEXT */),
        (_ctx.permissions.includes('edit-downlink'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`badge badge-light-success pointer`),
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_3",
              onClick: ($event: any) => (_ctx.editDownlink(data))
            }, _cache[34] || (_cache[34] = [
              _createElementVNode("i", { class: "fas fa-edit text-success" }, null, -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_33))
          : _createCommentVNode("v-if", true),
        (_ctx.permissions.includes('delete-downlink'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`badge badge-light-success ms-3 pointer`),
              onClick: ($event: any) => (_ctx.deleteDownlink(data.id, data.protocol))
            }, _cache[35] || (_cache[35] = [
              _createElementVNode("i", { class: "fas fa-trash text-danger" }, null, -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_34))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"]),
    _createVNode(_component_GenerateDownlink),
    _createCommentVNode("modal code")
  ], 64 /* STABLE_FRAGMENT */))
}